<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="true"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :loading="loading"
      backButtonText="Checklists"
      :modal-show="modal.removeItem"
      :showRightControls="true"
      :notificationMessage.sync="notificationMessage"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
    >

      <template #notification>
        {{notification.message}}
      </template>

      <template #modal>
        <modal-confirmation
          v-if="modal.removeItem"
          title="Delete Checklist Item?"
          description="
            If you delete this checklist item,
            it will be removed for every user of the website.
            All of the data that user had associated with this
            checklist item will also be permanantly deleted.
            This action is permanent and cannot be undone.
          "
          text-confirm="Permanantely Delete"
          text-cancel="Cancel"
          @confirm="confirmRemoveItem"
          @cancel="modal.removeItem = false"
        />
      </template>

      <template #right-action-buttons>
        <right-page-action-button
          @click="settings"
          :active="false"
          title="Settings"
          icon="settings"
        />
      </template>

      <template #title>
        {{checklist.name}}
      </template>

      <template #content>
        <div class="flex flex-col self-center flex-shrink-0 h-full mt-2">

          <div class="flex items-center justify-center h-48 mb-4 w-72 md:w-80 lg:w-96">
            <img
              v-if="checklist.picture"
              :src="checklist.picture.url"
              class="object-scale-down max-h-full rounded-lg shadow-lg select-none"
            />
          </div>

          <admin-checklist-item-component
            v-for="(item, index) in checklist.items" :key="`${index}`"
            @save="saveItem({index, updateKeys: $event})"
            :data.sync="item.item"
            :modified.sync="item.modified"
            class="mb-4"
          />
          <div
            v-if="checklist.items.length === 0"
            class="pt-10 w-72 md:w-80 lg:w-96 text-content"
          >
            No Checklist Items added yet
          </div>

          <div class="flex flex-col items-center justify-end flex-grow flex-shrink-0 my-5">
            <button-large
              @click="addItem"
              color="primary"
              class="self-center w-32"
            >
              New Item
            </button-large>
          </div>

          <div class="h-10 opacity-0">space...</div>

        </div>

      </template>

      <template #bottom-nav-center>
        <settings
          @click="settings"
          class="cursor-pointer settings"
          :size='37'
        />
      </template>

    </interior-page-content-container>
</template>

<script>
import Settings from 'vue-material-design-icons/Cog.vue';

import {
  ADMIN_CHECKLIST_GET,
  ADMIN_CHECKLIST_ITEM_POST,
  ADMIN_CHECKLIST_ITEM_PUT,
  ADMIN_CHECKLIST_ITEM_DELETE,
} from '@/store/actions';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import AdminChecklistItemComponent from '@/components/projects/checklists/AdminChecklistItemComponent.vue';
import RightPageActionButton from '@/components/shared/inputs/RightPageActionButton.vue';
import ButtonLarge from '../../../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'AdminChecklist',
  components: {
    Settings,
    InteriorPageContentContainer,
    ModalConfirmation,
    AdminChecklistItemComponent,
    ButtonLarge,
    RightPageActionButton,
  },
  data() {
    return {
      loading: true,
      selected: 1,
      search: '',
      checklist: {
        name: '',
        picture: null,
        id: null,
        items: [],
      },
      emptyItem: {
        modified: true,
        item: {
          name: '',
          url: '',
          description: '',
        },
      },
      modal: {
        removeItem: false,
      },
      itemIndexToDelete: null,
      notification: {
        show: false,
        type: 'alert',
        message: '',
      },
      notificationMessage: '',
      notificationTime: 5000,
      notificationType: 'success',
    };
  },
  computed: {
    serviceLists() { return this.checklists.filter((list) => list.type === 'Service'); },
    safetyLists() { return this.checklists.filter((list) => list.type === 'Safety'); },
  },
  created() {
    this.$store.dispatch(ADMIN_CHECKLIST_GET, this.$route.params.checklist_id)
      .then((checklist) => {
        this.checklist = {
          name: checklist.name,
          picture: checklist.picture,
          type: checklist.type,
          items: checklist.items.map((item) => ({
            item,
            modified: false,
          })),
        };
        this.sortItems();
      })
      .finally(() => { this.loading = false; });
  },
  methods: {
    showNotification(data) {
      this.notification = {
        show: true,
        type: data.type,
        message: data.message,
      };
      setTimeout(() => {
        this.notification.show = false;
      }, data.timeout);
    },
    sortItems() {
      this.checklist.items = this.checklist.items.sort((a, b) => {
        if (a.item.name.toLowerCase() < b.item.name.toLowerCase()) return -1;
        if (a.item.name.toLowerCase() > b.item.name.toLowerCase()) return 1;
        return 0;
      });
    },
    addItem() {
      this.checklist.items.push(
        JSON.parse(
          JSON.stringify(
            this.emptyItem,
          ),
        ),
      );
    },
    backNav() {
      this.$router.push({
        name: 'AdminChecklists',
      });
    },
    itemError(error, index) {
      this.checklist.items[index].modified = true;
      // TODO Test this properly, make the http requests
      //  fail and then do it or something
      if (error.response.status === 400) {
        this.notificationMessage = error.response.data;
        this.notificationType = 'alert';
        this.notificationType = 7000;
      } else {
        this.notificationMessage = 'An error has occured. Please try again later.';
        this.notificationType = 'alert';
        this.notificationType = 7000;
      }
    },
    saveItem(data) {
      const { item } = this.checklist.items[data.index];
      let itemData = {};

      if (item.id) {
        data.updateKeys.forEach((key) => { itemData[key] = item[key]; });
        this.$store.dispatch(ADMIN_CHECKLIST_ITEM_PUT, {
          checklistId: this.$route.params.checklist_id,
          itemId: item.id,
          itemData,
        })
          .catch((error) => this.itemError(error, data.index));
      } else {
        itemData = { ...item };
        this.$store.dispatch(ADMIN_CHECKLIST_ITEM_POST, {
          checklistId: this.$route.params.checklist_id,
          itemData,
        })
          .then((createdItem) => {
            this.checklist.items[data.index].item.id = createdItem.id;
            this.showNotification({
              type: 'success',
              message: 'Item has been added!',
              timeout: 4000,
            });
          })
          .catch((error) => this.itemError(error, data.index));
      }
      this.checklist.items[data.index].modified = false;
    },
    deleteItem(index) {
      const currentItem = this.checklist.items[index].item;
      if (currentItem.id) {
        this.itemIndexToDelete = index;
        this.modal.removeItem = true;
      } else {
        this.checklist.items.splice(index, 1);
      }
      console.log(index);
    },
    confirmRemoveItem() {
      this.modal.removeItem = false;
      this.$store.dispatch(ADMIN_CHECKLIST_ITEM_DELETE, {
        checklistId: this.$route.params.checklist_id,
        itemId: this.checklist.items[this.itemIndexToDelete]
          .item.id,
      })
        .then(() => {
          this.checklist.items.splice(this.itemIndexToDelete, 1);
        });
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    settings() {
      this.$router.push({
        name: 'AdminChecklistSettings',
        params: {
          checklist_id: this.$route.params.checklist_id,
        },
      });
    },
  },
};
</script>
