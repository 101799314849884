<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-md w-72 md:w-80 lg:w-96 bg-backfill cursor-default
      active:shadow-none
      checklist-item-component
    `"
  >
    <div class="flex flex-col items-start w-full px-2">

      <div class="flex items-center w-full mt-2">
        <p
          class="w-2 h-2 mr-2 rounded-full"
          :class="[modified ? 'bg-red-500' : 'bg-green-500']"
        ></p>
        <input
          @keyup="update('name', $event.target.value)"
          :value="data.name"
          autocomplete="off"
          placeholder="Item Name..."
          name="Item Name"
          class="`
            bg-transparent border-b-2 outline-none placeholder-secondary flex-grow
            focus:outline-none text-secondary font-bold border-transparent focus:border-secondary
          `"
        />
        <div
          @click="open = !open"
          class="flex justify-end ml-2 cursor-pointer text-content toggle-expansion"
        >
          <chevron-up v-if="open" />
          <chevron-down v-else />
        </div>
      </div>

      <div
        class="w-full mb-2 overflow-hidden transition-all duration-500 ease-in-out max-h-0"
        :class="{'max-h-96' : open}"
      >
        <div class="w-full py-2 overflow-scroll text-left text-input">

          <textarea
            @keyup="update('description', $event.target.value)"
            :value="data.description"
            placeholder="Description"
            name="Description"
            :rows="5"
            class="`
              w-full bg-transparent border-transparent placeholder-input
              border-b-2 outline-none focus:outline-none text-input
              focus:border-secondary
            `"
          />
          <input
            @keyup="update('url', $event.target.value)"
            :value="data.url"
            autocomplete="off"
            placeholder="Item URL..."
            name="Item URL"
            class="`
              w-full bg-transparent border-b-2 outline-none placeholder-input flex-grow
              focus:outline-none text-input border-transparent focus:border-secondary
            `"
          />
        </div>

        <div
          class="flex justify-end w-full text-content"
        >
          <content-save
            @click="save"
            :class="[modified && valid ? 'text-green-500 cursor-pointer' : 'text-content']"
            class="save"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import ContentSave from 'vue-material-design-icons/ContentSave.vue';

export default {
  name: 'ChecklistItemComponent',
  components: {
    ChevronUp,
    ChevronDown,
    ContentSave,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modified: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      origionalVal: {},
    };
  },
  computed: {
    valid() {
      return this.data.name.length > 0 && this.data.description.length > 0;
    },
  },
  watch: {
    data(newVal) {
      this.$emit('update:modified',
        this.origionalVal.name !== newVal.name
        || this.origionalVal.description !== newVal.description
        || this.origionalVal.url !== newVal.url);
    },
  },
  created() {
    this.origionalVal = this.data;
  },
  methods: {
    save() {
      if (this.modified) {
        const updateKeys = [];
        if (this.origionalVal.name !== this.data.name) updateKeys.push('name');
        if (this.origionalVal.description !== this.data.description) updateKeys.push('description');
        if (this.origionalVal.url !== this.data.url) updateKeys.push('url');
        this.$emit('save', updateKeys);
        this.origionalVal = this.data;
      }
    },
    update(key, val) {
      this.$emit('update:data', {
        ...this.data,
        [key]: val,
      });
    },
  },
};
</script>

<style>

</style>
